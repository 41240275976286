import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Branding } from "~/components";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  branding: {
    margin: "0.25em 0",
    "& + *": {
      margin: `${theme.spacing(1)}px 0`,
    },
  },
}));

export default function NotFoundPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Branding className={classes.branding} />

      <Typography component="p" variant="body1">
        Page not found
      </Typography>
    </div>
  );
}
