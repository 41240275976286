import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Branding, DownloadButton } from "~/components";
import { useDownloadInfo } from "~/components/DownloadButton";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  branding: {
    margin: "0.25em 0",
    "& + *": {
      margin: `${theme.spacing(1)}px 0`,
    },
  },
}));

export default function IndexPage() {
  const classes = useStyles();

  const { loading, downloadUrls } = useDownloadInfo();

  return (
    <div className={classes.root}>
      <AppBar />

      <Branding className={classes.branding} variant="h1" />

      <DownloadButton loading={loading} downloadUrls={downloadUrls} />
    </div>
  );
}
