import { Button, CircularProgress, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Alert } from "@material-ui/lab";
import * as Sentry from "@sentry/gatsby";
import { useAuth } from "~/auth";
import { AppBar, Branding } from "~/components";
import FacebookIcon from "~/images/facebook.svg";
import GoogleIcon from "~/images/google.svg";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  branding: {
    margin: "0.25em 0",
    "& + *": {
      margin: `${theme.spacing(1)}px 0`,
    },
  },
}));

export default function SignInPage() {
  const classes = useStyles();

  const {
    loading: loadingAuth,
    error: authError,
    authenticated,
    requestCustomToken,
    signInWithGoogle,
    signInWithFacebook,
  } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [tokenError, setTokenError] = React.useState();

  const sendTokenToApp = React.useCallback(async () => {
    const clientId = new URL(window.location.href).searchParams.get("clientId");
    const publicKey = new URL(window.location.href).searchParams.get("p");

    setLoading(true);
    setTokenError();

    try {
      await requestCustomToken(clientId, publicKey);
    } catch (error) {
      Sentry.captureException(error);
      setTokenError(error);
    }

    setLoading(false);
  }, [requestCustomToken]);

  React.useEffect(() => {
    (async () => {
      if (!authenticated) {
        return;
      }

      const redirectUrl = new URL(window.location.href).searchParams.get(
        "redirectUrl"
      );

      if (redirectUrl) {
        window.location.replace(redirectUrl);
        return;
      }

      await sendTokenToApp();
    })();
  }, [authenticated, sendTokenToApp]);

  return (
    <div className={classes.root}>
      {authenticated && <AppBar />}

      <Branding className={classes.branding} />

      {authError && (
        <Alert className={classes.alert} severity="error">
          {authError.message}
        </Alert>
      )}

      {loadingAuth || loading ? (
        <CircularProgress />
      ) : tokenError ? (
        <Alert
          className={classes.alert}
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              startIcon={
                <SvgIcon>
                  <RefreshIcon />
                </SvgIcon>
              }
              onClick={sendTokenToApp}
            >
              Retry
            </Button>
          }
        >
          {tokenError.message}
        </Alert>
      ) : authenticated ? (
        <Alert severity="success">You've signed in successfully!</Alert>
      ) : (
        <>
          <Button
            size="large"
            variant="outlined"
            startIcon={
              <SvgIcon>
                <GoogleIcon />
              </SvgIcon>
            }
            onClick={signInWithGoogle}
          >
            Sign in with Google
          </Button>
          <Button
            size="large"
            variant="outlined"
            startIcon={
              <SvgIcon>
                <FacebookIcon />
              </SvgIcon>
            }
            onClick={signInWithFacebook}
          >
            Sign in with Facebook
          </Button>
        </>
      )}
    </div>
  );
}
